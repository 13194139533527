<template>
  <div>
    <CCard>
      <CCardHeader>
        <h5 class="my-2" v-translate>Profile</h5>

        <div class="card-header-actions">
          <CButton color="primary" v-if="! editProfile" @click="editProfile = true">
            <translate>Edit</translate>
          </CButton>
          <template v-else>
            <CButton color="secondary" @click="disableEdit()">
              <translate>Cancel</translate>
            </CButton>
            <CButton color="primary" class="ml-1" @click="saveProfile()">
              <translate>Save</translate>
            </CButton>
          </template>
        </div>
      </CCardHeader>

      <CCardBody class="px-0">
        <CAlert show closeButton color="success" v-if="passwordChanged">
          <translate>Password changed successfully</translate>
        </CAlert>
        <CContainer fluid class="grid-stripped">
          <CRow>
            <CCol sm="2">
              <label v-translate>Name</label>
            </CCol>
            <CCol sm="6" v-if="editProfile">
              <CForm inline>
                <CInput placeholder="Enter your name" v-model="firstName"/>
                <CInput placeholder="Enter your surname" v-model="lastName" class="ml-1"/>
              </CForm>
            </CCol>
            <CCol sm="6" v-else>{{ firstName }} {{ lastName }}</CCol>
          </CRow>

          <CRow>
            <CCol sm="2"><label v-translate>Email</label></CCol>
            <CCol sm="6">{{ $store.state.login.user.email }}</CCol>

          </CRow>

          <CRow>
            <CCol sm="2"><label v-translate>Password</label></CCol>
            <CCol sm="6">
              <CButton v-if="editProfile" color="primary" @click="changePasswordShowModal = true">
                <translate>Change password...</translate>
              </CButton>
              <span v-else>*******</span>
            </CCol>
          </CRow>
        </CContainer>

      </CCardBody>
    </CCard>

    <CModal :title="$gettext('Change password')" :show.sync="changePasswordShowModal">
      <ChangePasswordForm :resetForm="changePasswordShowModal" show-back-button
                          @cancel="changePasswordShowModal = false" @password-changed="setPasswordChanged"/>
      <template #footer-wrapper><span></span></template>
    </CModal>

    <CCard>
      <CCardHeader>
        <h5 class="my-2">
          <translate>Language & Timezone</translate>
        </h5>

        <div class="card-header-actions">
          <CButton v-if="langZoneChanged" color="secondary" @click="resetLangZone()">
            <translate>Cancel</translate>
          </CButton>
          <CButton :disabled="! langZoneChanged" color="primary" class="ml-1" @click="saveLangZone()">
            <translate>Save</translate>
          </CButton>
        </div>
      </CCardHeader>

      <CCardBody class="px-0">
        <CContainer fluid class="grid-stripped">
          <CRow>
            <CCol sm="2">
              <label v-translate>Language</label>
            </CCol>
            <CCol sm="4">
              <CSelect
                  :options="languageOptions"
                  :value.sync="languageSelected"
              />
            </CCol>
          </CRow>
          <CRow>
            <CCol sm="2">
              <label v-translate>Timezone</label>
            </CCol>
            <CCol sm="4">
              <CSelect
                  :options="tzOptions"
                  :value.sync="tzSelected"
              />
            </CCol>
          </CRow>
        </CContainer>
      </CCardBody>
    </CCard>
  </div>

</template>

<script>
import ChangePasswordForm from "../../components/ChangePasswordForm.vue"
import UserService from "../../services/user.service"
import {mapActions, mapGetters} from "vuex";

export default {
  name: "UserProfile",

  components: {
    // Multiselect,
    ChangePasswordForm
  },

  data() {
    return {
      editProfile: false,
      changePasswordShowModal: false,
      passwordChanged: false,
      firstName: '',
      lastName: '',

      languageOptions: null,
      languageInitial: null,
      languageSelected: null,
      languageChanged: false,

      tzOptions: null,
      tzInitial: null,
      tzSelected: null,
      tzChanged: false
    }
  },

  watch: {
    languageSelected: function (newVal) {
      this.languageChanged = newVal !== this.languageInitial
    },

    tzSelected: function (newVal) {
      this.tzChanged = newVal !== this.tzInitial
    }
  },

  mounted() {
    this.firstName = this.user.first_name
    this.lastName = this.user.last_name

    UserService.getAvailableLanguages()
        .then((response) => {
          this.languageOptions = response.data.languages
          this.languageInitial = this.language
          this.languageSelected = this.languageInitial
        })

    UserService.getTimezones()
        .then((response) => {
          this.tzOptions = response.data.timezones
          this.tzInitial = this.user.timezone
          this.tzSelected = this.tzInitial
        })
  },

  computed: {
    langZoneChanged: function () {
      return this.languageChanged || this.tzChanged
    },
    ...mapGetters('login', ['user'])
  },

  methods: {
    ...mapActions('login', ['changeUserData']),
    disableEdit() {
      this.firstName = this.user.first_name
      this.lastName = this.user.last_name
      this.editProfile = false
    },

    async saveProfile() {
      await this.changeUserData({
        first_name: this.firstName,
        last_name: this.lastName
      })
      await this.notifyMessage({
        text: this.$gettext('User profile updated successfully'),
        messageType: 'success'
      })
      this.editProfile = false
    },

    setPasswordChanged() {
      this.changePasswordShowModal = false
      this.passwordChanged = true
    },

    resetLangZone() {
      this.languageSelected = this.languageInitial
      this.tzSelected = this.tzInitial
    },

    async saveLangZone() {
      await this.changeUserData({
        language: this.languageSelected,
        timezone: this.tzSelected,
      })

      this.languageChanged = false
      this.tzChanged = false

      this.languageInitial = this.user.language
      this.tzInitial = this.user.timezone

      await this.notifyMessage({
        text: this.$gettext('Language and timezone updated successfully'),
        messageType: 'success'
      })

      // TODO: try something better to reload sidebar
      setTimeout(() => document.location.reload(), 600)

    }
  }
}
</script>