<template>
  <div>
    <CRow>
      <CCol sm="12">
        <CInput
            :label="$gettext('Password')"
            type="password"
            v-model="password"
            required
            :is-valid="isPasswordValid"
            :invalid-feedback="getPasswordErrors"
        />
      </CCol>
      <CCol sm="12">
        <CInput
            :label="$gettext('Repeat password')"
            type="password"
            v-model="passwordRepeat"
            required
            :is-valid="isPasswordRepeatValid"
            :invalid-feedback="getPasswordRepeatErrors"
        />
      </CCol>
    </CRow>

    <CButton color="primary" class="float-right" @click="changePassword()">
      <translate>Save</translate>
    </CButton>

    <CButton v-if="showBackButton" class="float-right mr-2" color="secondary" @click="$emit('cancel')">
      <translate>Cancel</translate>
    </CButton>
  </div>
</template>


<script>
export default {
  name: 'ChangePasswordForm',

  props: {
    resetForm: Boolean,
    showBackButton: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      password: '',
      passwordRepeat: '',
      serverErrors: null,
      passwordChanged: false
    }
  },

  watch: {
    resetForm(newVal) {
      // console.log('Prop resetForm changed: ', newVal, ' | was: ', oldVal)
      if (newVal === false)
        this.resetFormFields()
    }
  },

  computed: {
    isPasswordValid() {
      if (this.serverErrors === null)
        return null
      return !this.serverErrors.new_password1;

    },

    getPasswordErrors() {
      if (this.serverErrors && this.serverErrors.new_password1)
        return this.serverErrors.new_password1.join(' ')
      return ''
    },

    isPasswordRepeatValid() {
      if (this.serverErrors === null)
        return null
      return !this.serverErrors.new_password2
    },

    getPasswordRepeatErrors() {
      if (this.serverErrors && this.serverErrors.new_password2)
        return this.serverErrors.new_password2.join(' ')
      return ''
    },
  },

  methods: {
    async changePassword() {
      this.serverErrors = null

      await this.$store.dispatch("login/changePassword", {
        password: this.password,
        passwordRepeat: this.passwordRepeat
      })
      this.resetFormFields()
      this.passwordChanged = true

      this.$emit('password-changed')

    },
    resetFormFields() {
      this.password = ''
      this.passwordRepeat = ''
      this.serverErrors = null
      this.passwordChanged = false
    }
  }
}
</script>